import { Box, Stack } from '@mui/system';
import NavigationMenu from './components/NavigationMenu';
import ProjectContextProvider from './schedule/ProjectContext';
import { Outlet, useLocation } from 'react-router';
import Project from './project/Project';


function Editor() {
  const location = useLocation();
  return (
    <Stack direction={'row'} width={'100%'}>
      <ProjectContextProvider>
        {
          location.pathname === '/' ?
          <Project />
          :
          <>
            <NavigationMenu />
            <Box sx={{flexGrow: 4}}>
              <Outlet />
            </Box>
          </>
        }
      </ProjectContextProvider>
    </Stack>
  );
}

export default Editor;
