import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemText';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import BlurCircularIcon from '@mui/icons-material/BlurCircular';
import TableViewIcon from '@mui/icons-material/TableView';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en-gb';
import dayjs from 'dayjs';

import { useLocation } from 'react-router';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { ProjectContext } from '../schedule/ProjectContext';
import { Divider, FormControlLabel, Stack, Switch, TextField } from '@mui/material';

import {Link as RouterLink} from 'react-router';

export default function NavigationMenu() {
  const location = useLocation();
  const projectContext = React.useContext(ProjectContext);

  const [expandMenu, setExpandMenu] = React.useState(false);
  const [scheduleAnchorEl, setScheduleAnchorEl] = React.useState<null | HTMLElement>(null);

  const [settingAnchorEl, setSettingAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleScheduleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setScheduleAnchorEl(event.currentTarget);
  };

  const handleSettingMenu = (event: React.MouseEvent<HTMLElement>) => {
    setSettingAnchorEl(event.currentTarget);
  };
  const closeScheduleMenu = () => {
    setScheduleAnchorEl(null);
  };

  const closeSettingMenu = () => {
    setSettingAnchorEl(null);
  };

  const toggleExpandMenu = () => {
    setExpandMenu(!expandMenu);
    window.dispatchEvent(new Event("resize"));
  }

  const getProject = () => {
    const query = new URLSearchParams(location.search);
    const project = query.get('project') ? query.get('project') : '';
    return encodeURIComponent(project!);
  }

  const isSelectedScheduleFile = (filename: string) => {

    if (!location.pathname.includes('/schedule-editor')) {
      return false;
    }

    const query = new URLSearchParams(location.search);
    const routeFilename = query.get('file');
    if (filename) {
      return routeFilename === filename;
    }

    return !routeFilename;
  }

  const ScheduleMenu = (
    <Menu
      PaperProps={{ sx: { minWidth: 200 } }}
      id="schedule-menu"
      anchorEl={scheduleAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      open={Boolean(scheduleAnchorEl)}
      onClose={closeScheduleMenu}
    >
      <MenuItem
        onClick={() => setScheduleAnchorEl(null)}
        component={RouterLink}
        to={{
          pathname: "/schedule-editor",
          search: "?project=" + getProject()
        }}
        // reloadDocument={true}
        sx={{
          backgroundColor: isSelectedScheduleFile('') ? '#bdbdbd' : ''
        }}
      >
        <ListItemText>New Schedule File</ListItemText>
      </MenuItem>

      <Divider />

      {projectContext.scheduleFileList.map(
        (file) => (
          <MenuItem key={file}
            onClick={() => setScheduleAnchorEl(null)}
            component={RouterLink}
            to={{
              pathname: "/schedule-editor",
              search: "?project=" + getProject() + '&file=' + encodeURIComponent(file)
            }}
            // reloadDocument={true}
            sx={{
              backgroundColor: isSelectedScheduleFile(file) ? '#bdbdbd' : ''
            }}
          >
            <ListItemText>{file}</ListItemText>
          </MenuItem>
      ))}
    </Menu>
  );

  const SettingMenu = (
    <Menu
      id="setting-menu"
      anchorEl={settingAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      open={Boolean(settingAnchorEl)}
      onClose={closeSettingMenu}
    >
      <MenuItem>
        <FormControlLabel
          control={
            <Switch
              checked={projectContext.autoSave}
              onChange={e => projectContext.setAutoSave(e.target.checked)}
              inputProps={{ 'aria-label': 'controlled' }}
            />}
          label="Auto Save" labelPlacement="start" />  
      </MenuItem>

      <MenuItem>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en-gb'}>
          <DatePicker label="UTC Date"
            sx={{ width: '100%' }}
            format='YYYY-MM-DD'
            value={projectContext.utcDate}
            onChange={(e) => projectContext.setUTCDate(e ? e : dayjs.utc())}
          />
        </LocalizationProvider>
      </MenuItem>

      <MenuItem>
        <TextField id="integration-time" label="Integration Time (s)" 
          value={projectContext.integrationTime}
          onChange={(e) => projectContext.setIntegrationTime(e.target.value as string)}
          sx={{ flexGrow: 1 }} />
      </MenuItem>

    </Menu>
  );

  return (
    <Stack
      width={expandMenu ? 270 : 50}
      height={'calc(100vh - 80px)'}
      sx={{
        backgroundColor: '#F5F5F5',
        borderRight: '1px solid rgba(0, 0, 0, 0.12)',
        justifyContent: 'space-between'
      }}
      role="presentation"
      className='navigation-bar'
    >
      <List>
        <ListItem key='target-catalog' disablePadding     
          sx={{
            backgroundColor: location.pathname.includes('/target') ? '#bdbdbd' : ''
          }}
        >
          <ListItemButton
            component={RouterLink}
            to={{
              pathname: "/target-catalogue",
              search: "?project=" + getProject()
            }}
            // reloadDocument={true}
            sx={{ alignItems: 'stretch' }}>
            <ListItemIcon><BlurCircularIcon /></ListItemIcon>
            {expandMenu && <ListItemText sx={{ width: '70%' }} primary='Target Catalogue' />}
          </ListItemButton>
        </ListItem>

        <ListItem key='corr-setting' disablePadding
          sx={{
            backgroundColor: location.pathname === '/correlator-setting' ? '#bdbdbd' : ''
          }}
        >
          <ListItemButton
            component={RouterLink}
            to={{
              pathname: "/correlator-setting",
              search: "?project=" + getProject()
            }}
            // reloadDocument={true}
            sx={{ alignItems: 'stretch' }}>
            <ListItemIcon><ViewWeekIcon /></ListItemIcon>
            {expandMenu && <ListItemText sx={{ width: '70%' }} primary='Correlator Configuration' />}
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding
          onClick={handleScheduleMenu}
          sx={{
            backgroundColor: location.pathname.includes('/schedule-editor') ? '#bdbdbd' : ''
          }}>
          <ListItemButton sx={{ alignItems: 'stretch' }}>
            <ListItemIcon><TableViewIcon /></ListItemIcon>
            {expandMenu && <ListItemText sx={{ width: '70%' }} primary='Schedule Editor' />}
          </ListItemButton>
        </ListItem>

        {ScheduleMenu}

      </List>

      <List id='export-project' >
        
        <ListItem disablePadding onClick={handleSettingMenu}>
          <ListItemButton sx={{ alignItems: 'stretch' }}>
            <ListItemIcon><SettingsOutlinedIcon /></ListItemIcon>
            {expandMenu && <ListItemText sx={{ width: '70%' }} primary='Settings' />}
          </ListItemButton>
        </ListItem>

        {SettingMenu}

        {/* <ListItem disablePadding>
          <ListItemButton sx={{ alignItems: 'stretch' }}>
            <ListItemIcon><DriveFileMoveOutlinedIcon /></ListItemIcon>
            {expandMenu && <ListItemText sx={{ width: '70%' }} primary='Export Project' />}
          </ListItemButton>
        </ListItem> */}

        <ListItem key='toggle-menu' className='expend-item' disablePadding
          onClick={toggleExpandMenu} >
          <ListItemButton>
            <ListItemIcon>
              {
                expandMenu ? 
                  <KeyboardDoubleArrowLeftIcon />
                  : <KeyboardDoubleArrowRightIcon onClick={toggleExpandMenu} />
              }
            </ListItemIcon>
          </ListItemButton>
        </ListItem>
      </List>

    </Stack>
  )
}

