import * as React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, 
  Button, Box } from "@mui/material";
import { Stack } from "@mui/system";
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';

export default function SampleTargetCatalogueDialog(
  props: {
    open: boolean,
    handleClose: () => void,
  }
) {
  const [content, setContent] = React.useState('');
  
  React.useEffect(() => {
    fetch('target_catelogue_sample.csv')
      .then(response => response.text())
      .then(data => setContent(data))
      .catch(error => console.error('Error:', error));
  }, []);

  const handleClose = () => {
    props.handleClose();
  }

  const handleDownload = () => {
    let filename = 'sample_target_catalogue.csv';
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + content);
    element.setAttribute('download', filename);

    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  return (
    <Dialog open={props.open} PaperProps={{ sx: {height: '80%'} }}
      maxWidth="md" fullWidth>
      <DialogTitle id="sample-catalogue-dialog-title">
        Sample Target Catalogue
      </DialogTitle>
      <DialogContent sx={{height: '100%'}}>
        <Stack spacing={3} sx={{ height: '100%' }}>
          <Stack direction={'row'} alignItems={'flex-end'}
          justifyContent={'space-between'}>
            <Button variant="text"
              onClick={handleDownload}
              startIcon={<DownloadOutlinedIcon />}>
              Download
            </Button>            
          </Stack>
          <Box
            sx={{ 
              border: '1px solid #bdbdbd',
              borderRadius: '10px',
              padding: '10px',
              height: '100%',
              overflow: 'scroll'
            }}
          >
            <pre style={{width:'100%'}}>
              {content}
            </pre>
          </Box>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
