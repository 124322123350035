import * as d3 from "d3";

import * as ATCAConstants from './ATCAConstants'
import * as AstroUtil from '../astro/AstroUtil';
import * as Transform from '../astro/Transform';
import * as Planets from '../astro/Planets';

// convert kb to mb, gb, tb
export const format_kbytes = (size: number) => {
  // 2 ** 10 = 1024
  const power = 2 ** 10;
  let n = 0;
  // k => kilo, m => mega, g => giga, t => tera
  const power_labels: any = { 0: 'k', 1: 'm', 2: 'g', 3: 't' };
  let newSize = size;
  while (newSize > power) {
    newSize /= power;
    n += 1;
  }
  
  return '' + Math.round(newSize) + power_labels[n] + 'b';
}

export const getZoomConfigurations = (correlatorSettings: any, name: string) => {
  for (let config of correlatorSettings) {
    if (config['name'] === name) {
      if (config['zoom'])
        return config['zoom'];
      else
        return [];
    }
  }

  return [];
}

export const getZoomBand = (correlatorSettings: any, 
  corrName: string, zoomName: string) => {
  for (let config of correlatorSettings) {
    if (config['name'] === corrName) {
      const zooms = config['zoom'] || [];

      for (let z of zooms) {
        if (z['name'] === zoomName) {
          return z;
        }
      }
    }
  }
  return null;
}

// export const validateZoomBands = (corr_modes: any) => {
//   const zooms = corr_modes['sub_band_configuration'];
//   const validities: any[] = [];

//   for (let zoom of zooms) {
//     const validity = validateZoomBand(corr_modes, zoom);
//     validities.push(validity);
//   }
//   return validities;
// }

export const validateZoomBand = (correlatorSettings: any,
  corrSetting: any, zoom: any): any => {
  const freqConfig = corrSetting['frequency_configuration'] || {};
  if (Number(zoom['centre_frequency']) === 0) {
    return {
      'status': 'valid',
      'message': ''
    };
  } else {
    const suggestedBand = caluculateBand(zoom['centre_frequency'], freqConfig);
    if (suggestedBand && suggestedBand['messages']) {
      return {
        'status': 'error',
        'color': '#d32f2f',
        'message': suggestedBand['messages'].join(' ')
      };
    }
  }

  if (!zoom || !zoom['id'] || !zoom['zoom'] || !corrSetting['correlator_setting']) {
    return {
      'status': 'valid',
      'message': ''
    };
  }

  // first, check if centre freq is within the subband range
  const startFreq = getStartFreq(freqConfig, zoom['band'], zoom['subband']);
  const endFreq = getEndFreq(freqConfig, zoom['band'], zoom['subband']);
  
  const centerFreq = Number(zoom['centre_frequency']);

  if ((centerFreq > endFreq) || (centerFreq < startFreq)) {
    return {
      'status': 'error',
      'color': '#d32f2f',
      'message': 'centre freqency is outside subband range'
    };
  }
  
  // then, check if the zoom band is within subband range
  const band = getZoomBand(correlatorSettings,
    corrSetting['correlator_setting'], zoom['zoom']); 

  if (!band) {
    return {
      'status': 'error',
      'color': '#d32f2f',
      'message': 'Could not find ' + corrSetting['correlator_setting']
    };   
  }

  const bandWidth = band['spectral_resolution'] * band['points'] / 1000;
  const bandStart = centerFreq - bandWidth / 2;
  const bandEnd = centerFreq + bandWidth / 2;

  if ((bandStart < startFreq) || (bandEnd > endFreq)) {
    return {
      'status': 'warning',
      'color': '#ff8f00',
      'message': 'zoom band spills over subband'
    };
  }

  // lastly check if the zoom band overlaps with another zoomband
  const zooms = corrSetting['sub_band_configuration'];
  for (let z of zooms) {
    // skip if it's self or no zoom set
    if (z['id'] === zoom['id'] || !z['id'] || !z['zoom'])
      continue;

    if ((zoom['band'] === z['band']) && (zoom['subband'] === z['subband'])) {
      const zBand = getZoomBand(correlatorSettings,
        corrSetting['correlator_setting'], z['zoom']);
      if (!zBand) {
        return {
          'status': 'error',
          'color': '#d32f2f',
          'message': 'invalid zoom band for ' + corrSetting['correlator_setting']
        };      
      }
      
      const zBandWidth = zBand['spectral_resolution'] * zBand['points'] / 1000;
      const zCentreFreq = Number(z['centre_frequency']);

      const bandOverlap = isBandsOverlap(centerFreq, bandWidth, zCentreFreq, zBandWidth);

      if (bandOverlap) {
        return ({
          'status': 'warning',
          'color': '#ff8f00',
          'message': 'zoom band overlaps with another zoom band'
        });
      }
    }
  }

  // all good
  return {
    'status': 'valid',
    'color': 'none',
    'message': ''
  };
}

export const zoomExists = (zooms: any[], band: number, subband: number, zoom: string) => {
  for (let z of (zooms||[])) {
    if ((z['band'] === band) && (z['subband'] === subband) &&
      (z['zoom'] === zoom)) {
      return true;
    }
  }
  return false;
}

export const isBandsOverlap = (centreFreq1: number, bandWidth1: number, centreFreq2: number, bandWidth2: number) => {
  const startFreq1 = centreFreq1 - bandWidth1 / 2;
  const endFreq1 = centreFreq1 + bandWidth1 / 2;

  const startFreq2 = centreFreq2 - bandWidth2 / 2;
  const endFreq2 = centreFreq2 + bandWidth2 / 2;

  if ((startFreq1 <= endFreq2) && (startFreq1 >= startFreq2)) {
    return true;
  }

  if ((endFreq1 <= endFreq2) && (endFreq1 >= startFreq2)) {
    return true;
  }

  return false;
}

// returns freq in MHz
export const getStartFreq = (freqConfig: any, band: number, subband: number) => {
  const freqName = 'centreFreq' + band;
  let freq = freqConfig[freqName];
  freq = (freq - ATCAConstants.IF_BAND_WIDTH / 2) 
    + (subband - 1) * ATCAConstants.IF_BAND_WIDTH / ATCAConstants.SUB_BANDS_PER_IF;

  return Number(freq.toFixed(3));
};

// returns freq in MHz
export const getEndFreq = (freqConfig: any, band: number, subband: number) => {
  const freqName = 'centreFreq' + band;
  let freq = freqConfig[freqName];
  freq = (freq - ATCAConstants.IF_BAND_WIDTH / 2) 
    + subband * ATCAConstants.IF_BAND_WIDTH / ATCAConstants.SUB_BANDS_PER_IF;

  return Number(freq.toFixed(3));
};

export const getTargets = (content: string) => {
  // get rid of bad characters and coments
  let str = content.replace(/[^\x00-\x7F]/g, "");
  str = str.replace(/^[#@][^\r\n]+[\r\n]+/mg, '');

  let targets = d3.csvParseRows(str, function (d, i) {
    if (d && d.length > 0) {
      const velocity = getVelocity(d.length > 4 ? d[4].trim() : '');

      return {
        name: d[0].trim(),
        ra: d.length > 1 ? d[1].trim() : '',
        dec: d.length > 2 ? d[2].trim() : '',
        coord_sys: (d.length > 3 ? d[3].trim() : '')||'J2000',
        id: Date.now() + i,
        velocity: velocity['velocity'],
        frame: velocity['frame'],
        convention: velocity['convention']
      };
    }
  });

  return targets;
}

const getVelocity = (vel_string: string) => {
  const velocity = {
    'velocity': '',
    'frame': 'lsr',
    'convention': 'radio'
  };

  if (vel_string && vel_string.trim()) {
    const vels = vel_string.trim().split(' ');
    if (vels.length > 0) {
      velocity['velocity'] = vels[0];

      if (vels.length > 1) {
        if (vels[1].trim().toLowerCase() === 'barycentric')
          velocity['frame'] = 'barycentric';
      }

      if (vels.length > 2) {
        if (vels[2].trim().toLowerCase() === 'optical')
          velocity['convention'] = 'optical';

        if (vels[2].trim().toLowerCase() === 'z')
          velocity['convention'] = 'z';
      }
    }
  }

  return velocity;
}

export const targetToString = (target: any) => {
  const velocity = getTargetVelocityString(target);
  let content = `${target['name']}, ${target['ra']}, ${target['dec']}, ${target['coord_sys']||'J2000'},`;
  if (velocity)
    content += ' ' + velocity

  return content;
}

export const getTargetVelocityString = (target: any) => {
  let velocity = '';
  if ((target['velocity']||'').trim()) {
    velocity =
      [
        target['velocity'],
        target['frame'] ? target['frame'] : 'lsr',
        target['convention'] ? target['convention'] : 'radio'
      ].join(' ');
  }

  return velocity; 
}

export const validateFrequencyConfiguration = (freqConfig: any) => {
  // make sure both bands are within a receiver
  const freq1 = freqConfig['centre_freq1'];
  const freq1_start = freq1 - ATCAConstants.IF_BAND_WIDTH / 2;
  const freq1_end = freq1 - ATCAConstants.IF_BAND_WIDTH / 2;

  const freq2 = freqConfig['centre_freq2'];
  const freq2_start = freq2 - ATCAConstants.IF_BAND_WIDTH / 2;
  const freq2_end = freq2 - ATCAConstants.IF_BAND_WIDTH / 2;

  let receiver = null;

  for (const rec of ATCAConstants.ReceiverRange) {
    if (freq1_start <= rec['end'] && freq1_start >= rec['start']
      && freq1_end <= rec['end'] && freq1_end >= rec['start']) {
      receiver = rec;
    } else {
      receiver = null;
      continue;
    }

    if (freq2_start <= rec['end'] && freq2_start >= rec['start']
      && freq2_end <= rec['end'] && freq2_end >= rec['start']) {
      break;
    }
  }
  
  if (!receiver) {
    return {
      'status': 'error',
      'message': 'one or both bands are outside of receiver range'
    };
  }

  // make sure bands don't over lap
  const bandOverlap = isBandsOverlap(
    freq1, 
    ATCAConstants.IF_BAND_WIDTH, 
    freq2, 
    ATCAConstants.IF_BAND_WIDTH);

  if (bandOverlap) {
    return {
      'status': 'warning',
      'message': 'bands overlap'
    };
  }

  return {
    'status': 'valid',
    'message': ''
  };
}

export const caluculateBand = (skyFrequency: number, corrMode: any) => {
  if (skyFrequency <= 0)
    return null;

  // recommend band, subband, zoom and centre freq for sky freq
  // work out which band first
  let band = 0;
  const centreFreq1 = Number(corrMode['centreFreq1']);
  if ((skyFrequency < centreFreq1 + ATCAConstants.IF_BAND_WIDTH / 2)
    && (skyFrequency > centreFreq1 - ATCAConstants.IF_BAND_WIDTH / 2)) {
    band = 1;
  }

  const centreFreq2 = Number(corrMode['centreFreq2']);
  if ((skyFrequency < centreFreq2 + ATCAConstants.IF_BAND_WIDTH / 2)
    && (skyFrequency > centreFreq2 - ATCAConstants.IF_BAND_WIDTH / 2)) {
    band = 2;
  }

  if (band === 0) {
    const messages = ['Could not calculate band.',
      'Sky frequency is outside both bands'];

    return {
      messages: messages
    };
  }

  // then work out which sub band
  let subband = 0;
  for (let i = 1; i <= ATCAConstants.SUB_BANDS_PER_IF; i++) {
    const startFreq = getStartFreq(corrMode, band, i);
    const endFreq = getEndFreq(corrMode, band, i);
    if ((skyFrequency < endFreq) && (skyFrequency > startFreq)) {
      subband = i;
      break;
    }
  }

  return {
    'band': band,
    'subband': subband
  };
}

export const isTargetWithZoom = (correlatorSettings: any, 
  target: any, zoomBand: any, corrSettingName: string) => {
  const skyFrequency = target['sky_frequency'];
  const zoom = getZoomBand(correlatorSettings, zoomBand['zoom'], corrSettingName);

  if (zoom && skyFrequency > 0) {
    const bandWidth = zoom['points'] * zoom['spectral_resolution'] / 1000;
    const centreFreq = Number(zoomBand['centre_frequency']);

    if (bandWidth > 0 && centreFreq > 0) {
      const highFreq = centreFreq + bandWidth / 2;
      const lowFreq = centreFreq - bandWidth / 2;

      if (skyFrequency > highFreq || skyFrequency < lowFreq) {
        return false;
      }
    }
  }

  return true;
}

export const getRaDec = (target: any, utcDate: any) => {
  const newValue = JSON.parse(JSON.stringify(target||{}));
  const name = ((target || {})['name'] || '').toLowerCase();
  if (Planets.getPlanetNames().includes(name)) {
    const date = utcDate || new Date();
    const planDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));  
    const radec = Planets.planet_position(planDate, name);
    const hour = radec['ra'] * 24 / 360;
    newValue['ra'] = AstroUtil.hmsstring(hour);
    newValue['dec'] = AstroUtil.dmsstring(radec['dec']);
    return newValue;
  }

  const coord = (target || {})['coord_sys'] || 'J2000';
  if (coord.toLowerCase() !== 'j2000') {
    // convert galactic to ra/dec

    if (coord.toLowerCase() === 'galactic') {
      newValue.l = Number((target || {})['ra']);
      newValue.b = Number((target || {})['dec']);
    }
    return Transform.coord_convert(newValue);
  }

  return target;
}

export const getFullScheduleName = (project: string, scheduleName: string) => {
  const projectComponents = project.split('/');
  let full_name = project + '-' + scheduleName.replace('.sch', '.json')
  if (projectComponents.length > 1) {
    full_name = projectComponents[0] + '-' 
        + projectComponents[projectComponents.length - 1]
        + '-' + scheduleName.replace('.sch', '.json')
  }

  return full_name;
}

//
// https://www.narrabri.atnf.csiro.au/observing/users_guide/html/atug.html#caobs-Commands
// instruction: 
// start or (start 1/1) => [1 ,... lastscan]
// start n => [n, ... lastscan]
// start n-m => [n, ... m] (including m)
// start n/l => [n, ... lastscan] + ([1, ... lastscan] * (l-1) )
// start n-m/l => [n, ... m] * l
export const instructionToScans = (instruction: string, length: number): number[] => {
  let scans: number[] = [];

  if (!instruction || !instruction.includes('start')) {
    return scans;
  }

  const parts = instruction.split(' ');
  if (parts.length < 2) {
    for (let i = 1; i <= length; i++) {
      scans.push(i);
    }
    return scans;
  }

  const rangeRepeatParts = parts[1].split('/');
  let rangeStr = '';
  let repeatStr = '1';

  rangeRepeatParts.length === 2 
  ? 
  [rangeStr, repeatStr] = rangeRepeatParts
  : 
  rangeStr = rangeRepeatParts[0];

  const rangeParts = rangeStr.split('-');
  let start = 1;
  let end = length;
  rangeParts.length === 2 
  ? 
  [start, end] = rangeParts.map(Number)
  : 
  start = Number(rangeParts[0]);

  const repeat = Number(repeatStr)
  for (let i = start; i <= end; i++) {
    scans.push(i);
  }
  
  if (repeat > 1) {
    let newStart = (rangeParts.length === 2 ? start : 1)

    const arr = [];
    for (let i = newStart; i <= end; i++) {
      arr.push(i);
    }

    const repeatArr = Array(repeat - 1).fill(arr).flat();
    scans = scans.concat(repeatArr);
  }

  return scans;
}