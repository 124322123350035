// RouteDetector.tsx
import React, { useEffect } from 'react';
import { useNavigate, useLocation, unstable_usePrompt } from 'react-router';

interface LeaveDetectorProps {
  isDirty: boolean;
  children: React.ReactNode;
}

const LeaveDetector: React.FC<LeaveDetectorProps> = ({ isDirty, children }) => {
  const history = useNavigate();
  const location = useLocation();
  
  unstable_usePrompt({
    message: "You have unsaved changes. Do you want to leave this page?",
    when: isDirty});


  useEffect(() => {    
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
        console.log('Location changed');

        if (isDirty) {
            event.preventDefault();
            event.returnValue = '';
        }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [history, location, isDirty]);

  return <>{children}</>;
};

export default LeaveDetector;
