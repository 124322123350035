import * as React from 'react';
import Stack from '@mui/material/Stack';
import { Checkbox, Chip, Divider, IconButton, ListItemText, 
  Paper, Tooltip, Typography, Tabs, Tab,
  Button} from '@mui/material';

import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import CompareIcon from '@mui/icons-material/Compare';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import * as ATCAConstants from '../util/ATCAConstants'
import * as Utils from '../util/Utils'

import SourceGraph from '../graphs/SourceGraph';

import {ProjectContext} from './ProjectContext';
import {ScheduleContext} from './ScheduleContext';
import CalibratorDialog from '../components/CalibratorDialog';
import MosaicDialog from '../components/MosaicDialog';

import 'dayjs/locale/en-gb';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const ITEM_HEIGHT = 35;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 12 + ITEM_PADDING_TOP,
      // width: 250,
    },
  },
};


export default function ScanEditor(
  props: {
    scan: any,
    setScan: (s: any) => void,
}) {
  const projectContext = React.useContext(ProjectContext);
  const scheduleContext = React.useContext(ScheduleContext);
  
  const [scan, setScan] = React.useState<any>(props.scan);
  const [showCalibratorDialog, setShowCalibratorDialog] = React.useState(false);
  const [showMosaicDialog, setShowMosaicDialog] = React.useState(false);

  const [tabValue, setTabValue] = React.useState(0);

  const handleChangeTabValue = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const closeCalibratorDialog = () => {
    setShowCalibratorDialog(false);
  }

  const closeMosaicDialog = () => {
    setShowMosaicDialog(false);
  }

  const handleUpdateMosaic = (mosaicSetup: any) => {
    const totalDuration = mosaicSetup['total_duration_s']||0;
    const newScan = { ...scan, 
      'mosaic': mosaicSetup,
      'duration': Number(totalDuration)
    };
    props.setScan(newScan);
  }

  const addCalibrator = (cal: any) => {
    const scans: any[] = scheduleContext.getScans();
    const newScans = [...scans];
    const newScan = {
      name: cal['name'],
      id: Date.now(),
      isCalibrator: true,
      coord_sys: 'J2000',
      ra: cal['rightascension'],
      dec: cal['declination'],
      calCode: '',
      duration: 300,
      startTime: '',
      startDate: '',
      scanType: 'Normal',
      pointing: 'Global',
      correlator_configuration: scan['correlator_configuration']||'',
    };

    // find index of given target and add cal before target
    var index = newScans.map(function(e) { return e.id; }).indexOf(scan['id']);
    newScans.splice(index, 0, newScan);
    scheduleContext.updateScans(newScans);
  }

  React.useEffect(() => {
    setScan(props.scan);
  }, [props.scan])


  const getTarget = (s: any) => {
    if (s['isCalibrator']) {
      return s;
    }
    
    const targetList = Utils.getTargets(projectContext.targetFile);
    const targets = targetList.filter((t: any) => (t['name'] === s['name']));
    if (targets && targets.length > 0) {
      return targets[0];
    }
  
    return null;
  }  

  const setScanValue = (value: any, fieldName: string) => {
    const newScan = { ...scan, [fieldName]: value};
    props.setScan(newScan);
  }

  const handleScanIntentChange = (event: SelectChangeEvent<string[]>) => {
    const value = event.target.value;
    const calCodes: string[] = (typeof value === 'string') ? value.split(',') : value;
    setScanValue(calCodes, 'scan_intents')
  }

  const AdvancedSourceConfig = (
      <Stack spacing={2}>
        <Stack direction="row" spacing={3} sx={{ alignItems: 'flex-end' }}>
          <TextField id="PointingOffset1" label="PointingOffset1" variant="standard"
            value={scan['PointingOffset1'] || 0}
            onChange={(e) => setScanValue(Number(e.target.value), 'PointingOffset1')}
          />

          <TextField id="PointingOffset2" label="PointingOffset2" variant="standard"
            value={scan['PointingOffset2'] || 0}
            onChange={(e) => setScanValue(Number(e.target.value), 'PointingOffset2')}
          />
        </Stack>

        <TextField id="comment" label="Comment" variant="standard"
          value={scan['comment'] || ''}
          onChange={(e) => setScanValue(e.target.value, 'comment')}
          sx={{ flexGrow: '1' }} />

        <TextField id="command" label="Command" variant="standard"
          value={scan['command'] || ''}
          onChange={(e) => setScanValue(e.target.value, 'command')}
          sx={{ flexGrow: '1' }} />

        <Stack direction="row" spacing={3} sx={{ alignItems: 'flex-end' }}>
          <FormControl variant="standard"
            sx={{ flexGrow: 4 }}>
            <InputLabel id="average">Average</InputLabel>
            <Select
              displayEmpty={true}
              labelId="average-label"
              id="average-select-value"
              label="Average"
              value={scan['average'] || ''}
              onChange={(e) => setScanValue(e.target.value, 'average')}
            >
              {
                Array.from({ length: 6 }, (_, i) => i + 1).map((n) => (
                  <MenuItem key={n} value={n}>
                    {n}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>

          <FormControl variant="standard"
            sx={{ flexGrow: 4 }}>
            <InputLabel id="environment">Environment</InputLabel>
            <Select
              displayEmpty={true}
              labelId="environment-label"
              id="environment-select-value"
              label="Environment"
              value={scan['environment'] || 0}
              onChange={(e) => setScanValue(e.target.value, 'environment')}
            >
              {
                Array.from(Array(32).keys()).map((n) => (
                  <MenuItem key={n} value={n}>
                    {n}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>

          <TextField id="tvChan" label="tvChan" variant="standard"
            value={scan['tvChan'] || ''}
            onChange={(e) => setScanValue(Number(e.target.value), 'tvChan')}
          />

          <FormControl variant="standard"
            sx={{ flexGrow: 4 }}>
            <InputLabel id="wrap">Wrap</InputLabel>
            <Select
              displayEmpty={true}
              labelId="wrap-label"
              id="wrap-select-value"
              label="Wrap"
              value={scan['wrap'] || 'Closest'}
              onChange={(e) => setScanValue(e.target.value, 'wrap')}
            >
              {
                ['Closest', 'South', 'North'].map((w) => (
                  <MenuItem key={w} value={w}>
                    {w}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Stack>
      </Stack>
  )

  function getTotalData(): string {
    const config = projectContext.getCorrelatorConfiguration(
      scan['correlator_configuration']);
      let usage = '';
    if (config && config['name']) {
      const dataRate = projectContext.calculateDataRate(config['name'], 
        config['frequency_configuration']['receiver']);
      usage = Utils.format_kbytes(dataRate * 60 * 60) + '/hr';

      if (scan['duration']) {
        usage = Utils.format_kbytes(dataRate * scan['duration']) + ' ' + usage;
      }
    }

    return usage;
  }

  function getReceiver(): string {
    const config = projectContext.getCorrelatorConfiguration(
      scan['correlator_configuration']);
    const frequencyConfig = config['frequency_configuration']||{};

    return frequencyConfig['receiver'];
  }

  function renderCorrelatorConfig(config: any): React.ReactNode {
    const receiverName = config['frequency_configuration']['receiver'];
    const setting = projectContext.getCorrelatorSetting(
      config['correlator_setting'], receiverName);
    const frequencyConfig = setting['frequency_configuration']||{};

    return (<Stack direction="row" spacing={2}>
      <Typography variant="body2">
        {frequencyConfig['receiver']}
      </Typography>
      <Typography variant="body2">
        {(setting['spectral_points_per_subband'] 
          * setting['subbands_per_IF']
          * setting['number_of_IF'])} points
      </Typography>

      <Typography variant="body2">
        {(config['sub_band_configuration'] || []).length} zooms
      </Typography>

      <Typography variant="body2">
        {projectContext.calculateDataRate(config['name'], receiverName)} kB/s
      </Typography>
    </Stack>);
  }

  const SourceConfig = (
    <Stack spacing={2}>
      <Stack direction={'row'} spacing={3} 
        sx={{ alignItems: 'flex-end', width: '90%' }}>

        <FormControl variant="standard" 
          sx={{ width: 'calc(90% - 50px)', maxWidth: '700px'}}>
          <InputLabel id="scan-intent-label">Intents</InputLabel>
          <Select
            labelId="scan-intent-label"
            id="scan-intents"
            label="Intents"
            displayEmpty={true}
            multiple
            value={(scan['scan_intents'] || []) as string[]}
            onChange={handleScanIntentChange}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={MenuProps}
          >
            <Typography variant='h6' color={'primary'} sx={{marginLeft: '30px'}}>Normal</Typography>
            {ATCAConstants.SCAN_INTENTS['normal'].map((intent) => (
              <MenuItem key={intent['name']} value={intent['name']}>
                <Checkbox checked={(scan['scan_intents'] || []).indexOf(intent['name']) > -1} />
                <ListItemText primary={intent['name']} 
                  secondary={
                    <Typography variant="body2">
                      {intent['note']}
                    </Typography>
                  }                
                />
              </MenuItem>
            ))}

            {
              Boolean(scheduleContext.getScheduleInfo()['advance_mode']) &&
                <Divider />
            }
            {
              Boolean(scheduleContext.getScheduleInfo()['advance_mode']) &&
                <Typography variant='h6' color={'primary'} sx={{ marginLeft: '30px' }}>Advanced</Typography>
            }
            {
              Boolean(scheduleContext.getScheduleInfo()['advance_mode']) &&
              ATCAConstants.SCAN_INTENTS['advanced'].map((intent: any) => (
                <MenuItem key={intent['name']} value={intent['name']}>
                  <Checkbox checked={(scan['scan_intents'] || []).indexOf(intent['name']) > -1} />
                  <ListItemText primary={intent['name']} />
                </MenuItem>
              ))
            }
          </Select>
        </FormControl>

        { 
          (!scan['isCalibrator'] 
            && 
            (scan['coord_sys']||'J2000').toLowerCase() !== 'azel')
          &&
          <Tooltip title="Search Calibrator">
            <IconButton color="primary" sx={{width: '50px'}}
              aria-label="setting" 
              onClick={() => {setShowCalibratorDialog(true)}}
            >
              <CompareIcon />
            </IconButton>
          </Tooltip>
        } 
      </Stack>
      
      <Stack direction="row" spacing={3} sx={{ alignItems: 'flex-end' }}>

      <FormControl variant="standard" 
          sx={{flexGrow: 4, maxWidth: '50%'}}>
          <InputLabel id="correlator-setting">Correlator Configuration</InputLabel>
          <Select
            displayEmpty={true}
            labelId="cor-setting-label"
            id="cor-setting-select-value"
            label="Correlator Configuration"
            value={scan['correlator_configuration'] || ''}
            renderValue={(selected) => selected}
            onChange={(e) => setScanValue(e.target.value, 'correlator_configuration')}
          >
            {
              projectContext.correlatorConfigurations.map((config, index) => (
                <MenuItem key={config['name'] + '-' + index} value={config['name']}>
                  <ListItemText primary={config['name']}
                    secondary={renderCorrelatorConfig(config)}
                  />
                </MenuItem>
              ))
            }
          </Select>
        </FormControl>

        <TextField id="duration" label="Duration (seconds)" variant="standard" 
          value={scan['duration'] || 0}
          disabled={scan['scanType'].toLowerCase() === 'mosaic' ? true : false}
          onChange={(e) => setScanValue(Number(e.target.value), 'duration')}
          sx={{ maxWidth: '20%', flexGrow: '1' }} />
        
        <Chip label={getTotalData()} 
          variant="outlined" sx={{ maxWidth: '30%', flexGrow: '1' }} />

        {
          scheduleContext.getScheduleInfo()['schedule_type'] === 'lst'
          &&
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker label="LST Start time"
              format="HH:mm"
              value={dayjs(scan['lst_start_time'] || '', 'HH:mm')}
              onChange={(e) => setScanValue(e ? e.format('HH:mm') : '', 'lst_start_time')}
            />
          </LocalizationProvider>
        }

        {
          scheduleContext.getScheduleInfo()['schedule_type'] === 'utc'
          &&
          <React.Fragment>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en-gb'}>
                <DateTimePicker label="UTC start date and time" 
                  sx={{ maxWidth: '40%', flexGrow: '1' }} 
                  format='YYYY-MM-DD HH:mm:ss'
                  value={scan['utc_start_date'] ? dayjs.utc((scan['utc_start_date'])) : null}
                  onChange={(e) => setScanValue(e, 'utc_start_date')}
                />
              </LocalizationProvider>
          </React.Fragment>
        }

      </Stack>

      <Stack direction="row" spacing={3} sx={{ alignItems: 'flex-end' }}>
        <FormControl variant="standard" sx={{ width: '30%' }}>
          <InputLabel id="pointing-label">Pointing</InputLabel>
          <Select
            labelId="pointing-label"
            displayEmpty={true}
            id="pointing"
            label="Pointing"
            value={scan['pointing'] || ''}
            onChange={(e) => setScanValue(e.target.value, 'pointing')}
          >
            {
              ATCAConstants.POINTINGS.map((type) => (
                <MenuItem key={type} value={type}>{type}</MenuItem>
              ))
            }
          </Select>
        </FormControl>

        <FormControl variant="standard" sx={{ width: '40%' }}>
          <InputLabel id="scan-type-label">Scan Type</InputLabel>
          <Select
            labelId="scan-type-label"
            displayEmpty={true}
            id="scan-type"
            label="Type"
            value={scan['scanType'] || ''}
            onChange={(e) => setScanValue(e.target.value, 'scanType')}
          >
            {
              ATCAConstants.SCAN_TYPES.map((type) => (
                <MenuItem key={type} value={type}>{type}</MenuItem>
              ))
            }
          </Select>
        </FormControl>

        { 
          <Button color="primary" 
            sx={{width: '150px',
              display: scan['scanType'].toLowerCase() !== 'mosaic' ? 'none' : 'inherit'
            }}
            aria-label="setting" variant="outlined"
            onClick={() => {setShowMosaicDialog(true)}}
          >
          Mosaic Setup
          </Button>
        }

      </Stack>

      {
        Boolean(scheduleContext.getScheduleInfo()['advance_mode']) 
        &&
        AdvancedSourceConfig
      }

    </Stack>
  );

  return (
    <Stack sx={{flexGrow: 4, marginLeft: '10px',}}>
      <CalibratorDialog 
        open={showCalibratorDialog}
        target={getTarget(scan)}
        handleAddCalibrator={addCalibrator}
        utcDate={projectContext.utcDate}
        handleClose={closeCalibratorDialog}  
        receiver={getReceiver()}
      />

      <MosaicDialog
        open={showMosaicDialog}
        target={getTarget(scan)}
        mosaic={scan['mosaic']||{}}
        handleUpdateMosaic={handleUpdateMosaic}
        utcDate={projectContext.utcDate}
        handleClose={closeMosaicDialog}  
      />


      <Tabs value={tabValue} onChange={handleChangeTabValue}>
        <Tab label="Source"/>
        <Tab label="Graph"/>
      </Tabs>

      <Paper hidden={tabValue !== 0}
        sx={{flexGrow: 4, padding: '10px', overflowY: 'scroll' }}>
          {SourceConfig}
      </Paper>

      <Paper hidden={tabValue !== 1}
        sx={{flexGrow: 4}}>
        <SourceGraph utcDate={projectContext.utcDate}
          source={getTarget(scan)} refresh={tabValue}/>
      </Paper>
    </Stack>
  )
}

