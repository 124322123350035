import React from "react";
import * as d3 from "d3";

//chart component
export default function MosaicGraph(
  props: {
    mosaicSettings: any,
    refresh: number,
  }) {
  // svg element
  const svgRef = React.useRef<any>();

  const [windowSize, setWindowSize] = React.useState({
    width: 0,
    height: 0,
  });

  React.useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  //draws chart
  React.useEffect(() => {
    draw();
  }, [windowSize, props.mosaicSettings, props.refresh]);

const getXYTranslate = (d: any, scale: any) => {
  const x = scale.xScale(d['ra']);
  const y = scale.yScale(d['dec']);

  return `translate(${x}, ${y})`;
}

  const drawTargets = (scale: any) => {
    const svg = d3.select('#mosaic_graph');
    svg.append("g")
    .attr('class', 'beams')
    .selectAll()
      .data(props.mosaicSettings['pointings']||[])
      .join('circle')
      .attr('class', 'beam')
      .attr('r', 3)
      .attr('id', (d: any) => d['name'])
      .attr('transform', (d) => getXYTranslate(d, scale))
      .attr('fill', '#757575');
  }

  const drawGraph = (): {xScale: any, yScale: any} | null => {
    const margin = {
      top: 50,
      left: 50,
      right: 20,
      bottom: 50
    }

    // do not need to draw anything if it's not visiable
    // since could've got here because planned date changed on ListView
    if (!d3.select(svgRef.current).node())
      return null;

    const node = d3.select(svgRef.current).node();
    let width = node.clientWidth;
    let height = node.clientHeight;

    d3.select(svgRef.current).selectAll('*').remove();

    const svg = d3.select(svgRef.current)
      .style('background-color', '#ECEFF1')
      .append("g")
      .attr('id', 'mosaic_graph')
      .attr("transform",
        "translate(" + margin.left + "," + margin.top + ")");
    
    width = width - margin.left - margin.right;
    height = height - margin.top - margin.bottom;

    width = width < height? width : height;
    height = width;

    const xOffsetScale = d3.scaleLinear().rangeRound([0, width]);
    const yOffsetScale = d3.scaleLinear().rangeRound([0, height]);

    const ras = (props.mosaicSettings['pointings']||[]).map(
      (x: any) => {return x['ra']}
    );
    const decs = (props.mosaicSettings['pointings']||[]).map(
      (x: any) => {return x['dec']}
    );

    const xMin = Number(d3.min(ras));
    const xMax = Number(d3.max(ras));
    const yMin = Number(d3.min(decs));
    const yMax = Number(d3.max(decs));

    xOffsetScale.domain([xMin, xMax]);
    yOffsetScale.domain([yMax, yMin]);

    // Add the X Axis
    svg.append("g")
      .attr("transform", "translate(0," + height + ")")
      .call(d3.axisBottom(xOffsetScale));

    svg.append("text")
      .attr("transform",
        "translate(" + (width / 2) + " , " + (height + 35) + ")")
      .style("text-anchor", "middle")
      .text('RA (hours)');

    // Add the Y Axis
    svg.append("g")
      .call(d3.axisLeft(yOffsetScale));

    // text label for the y axis
    svg.append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", 0 - margin.left)
      .attr("x", 0 - (height / 2))
      .attr("dy", "1em")
      .style("text-anchor", "middle")
      .text("DEC (°)");

      return {xScale: xOffsetScale, yScale: yOffsetScale};
    }

  const draw = () => {

    if (!props.mosaicSettings) {
      return;
    }
    const scale = drawGraph();
    drawTargets(scale);
  }

  return (
    <svg width='100%' height='100%' ref={svgRef}>
    </svg>
  );
};