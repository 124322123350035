import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import React from 'react';
import { useLocation } from 'react-router';
import { get, post } from 'aws-amplify/api';

export const INIT_SCHEDULE_FILE = {
  document: 'ATCA BIGCAT scheduler file',
  version: '1.0',
  schedule_info: {
    owner: '',
    observer: '',
    proposal_code: '',
    schedule_type: 'relative',
    utc_start_time: '',
    utc_start_date: '',
    lst_start_time: ''
  },
  scans: [],
};

export const ScheduleContext = React.createContext({
  filename: '' as string,
  projectName: '' as string,
  schedule: {} as any,
  setSchedule: (_schedule: any) => {},

  getScheduleInfo: (): any => { return {} },
  getScans: () : any[] => { return []},
  updateScheduleInfo: (scheduleInfo: any) => {},
  updateScans: (scans: any[]) => { },

  getSimulationInstructions: () : string[] => { return []},
  updateSimulationInstructions: (_instructions: string[]) => { },

  getSimulationDate: () : string => { return ''},
  updateSimulationDate: (_date: string) => { },

  deploySchedule: (content: any, scheduleFileName: string) => {},
  saveSchedule: () => {},
  saveAsSchedule: (fname: string, callback: () => any) => { },
  isDirty: false, 
  setIsDirty: (d: boolean) => { },

});

export default function ScheduleContextProvider(props: any) {
  const location = useLocation();
  const [snackMessage, setSnackMessage] = React.useState<any>(null);

  const [schedule, setSchedule] = React.useState<any>(INIT_SCHEDULE_FILE);
  const [projectName, setProjectName] = React.useState<string>('');
  const [filename, setFilename] = React.useState<string>('');
  const [isDirty, setIsDirty] = React.useState<boolean>(false);


  React.useEffect(() => {
    console.log('schedule - load schedule file')
    const queryParams = new URLSearchParams(location.search);
    let projectName: string = queryParams.get('project')!;
    const fname: string = queryParams.get('file')!;

    setProjectName(projectName ? projectName : '');
    setFilename(fname ? fname : '');

    if (!projectName) {
      setSnackMessage({
        severity: 'error',
        message: 'No project provided!'
      });
      return;
    }

    if (!fname) {
      // new schedule, deep copy schedule 
      const newSchedule = JSON.parse(JSON.stringify(INIT_SCHEDULE_FILE));
      setSchedule(newSchedule);

      return;
    }

    const fullfilename = projectName + '/' + fname;
    const getOperation = get(
      {
        apiName: 'cabb',
        path: '/get_file_content',
        options: {
          queryParams: {
            filename: fullfilename
          }
        }          
      }
    );

    getOperation.response
      .then(response => response.body.json())
      .then((response: any) => {
        if (response['status'] === 'success') {
          const fileContent = response['file_content'];
          setSchedule(fileContent);
          setIsDirty(false);
        } else {
          let message = response['message'];
          if (!message)
            setSnackMessage({
              severity: 'error',
              message: 'Could not retrieve schedule file from server!'
            });
        }
      })
      .catch((err: Error) => {
        setSnackMessage({
          severity: 'error',
          message: 'Could not retrieve schedule file from server!'
        });
      });
  }, [location]);

  const getSimulationDate = (): string => {
    const date =  schedule['simulation_date'];
    return date || '';
  }

  const updateSimulationDate = (date: string) => {
    const currentDate = schedule['simulation_date'];
    if (currentDate === date) 
      return;

    setSchedule({
      ...schedule,
      'simulation_date': date
    });
    setIsDirty(true);  
  }

  const getSimulationInstructions = (): string[] => {
    const instructions =  schedule['simulation_instructions'];
    return instructions||[];
  }  

  const updateSimulationInstructions = (instructions: string[]) => {
    setSchedule({
      ...schedule,
      'simulation_instructions': instructions
    });
    setIsDirty(true);  
  }

  const getScheduleInfo = () => {
    return schedule['schedule_info'];
  }

  const getScans = (): any[] => {
    return schedule['scans'];
  }

  const updateScheduleInfo = (scheduleInfo: any) => { 
    setSchedule({
      ...schedule,
      'schedule_info': scheduleInfo
    });
    setIsDirty(true);
  }

  const updateScans = (scans: any[]) => { 
    setSchedule({
      ...schedule,
      'scans': scans
    })
    setIsDirty(true);
  }

  const deploySchedule = (content: any, scheduleFileName: string) => {
    if (!content) {
      return;
    }

    const postOperation = post(
      {
        apiName: 'cabb',
        path: '/deploy_schedule',
        options: {
          body: {
            content: content,
            filename: scheduleFileName
          },
          headers: {'content-type': 'application/json'}
        }
      }
    );

    postOperation.response
      .then(response => response.body.json())  
      .then((response: any) => {
        if (response['status'] === 'success') {
          setIsDirty(false);
          setSnackMessage({
            severity: 'success',
            message: response['message']
          });
        } else {
          const message = response['message'];
          if (message)
            setSnackMessage({
              severity: 'error',
              message: message
            });
        }
      })
      .catch((err: Error) => {
        setSnackMessage({
          severity: 'error',
          message: 'Could not deploy schedule!'
        });
      });
  }

  const saveSchedule = () => {
    if (filename) {
      saveAsSchedule(filename, () => null);
    }
  }

  const saveAsSchedule = (fname: string, callback: () => any) => {
    if (fname) {
      const fullfilename = projectName + '/' + fname;

      // remove ra, dec and velocity info from schedule
      const newSchedule = JSON.parse(JSON.stringify(schedule));
      for (const scan of newSchedule['scans']) {
        if (scan['isCalibrator']) {
          continue;
        }
        delete scan['dec'];
        delete scan['ra'];
        delete scan['velocity'];
      }

      const data = {
        filename: fullfilename,
        content: newSchedule
      };

      const postOperation = post(
        {
          apiName: 'cabb',
          path: '/save_file',
          options: {
            body: data,
            headers: {'content-type': 'application/json'}
          }
        }
      );
  
      postOperation.response
        .then(response => response.body.json())
        .then((response: any) => {
          if (response['status'] === 'success') {
          setIsDirty(false);
          setSnackMessage({
            severity: 'success',
            message: fullfilename + ' changes saved!'
          });
          if (callback) {    
            setTimeout( () => { 
            callback();
          }, 500 );
          }
        } else {
          const message = response['message'];
          if (message)
            setSnackMessage({
              severity: 'error',
              message: message
            });
        }
      })
      .catch((err: Error) => {
        setSnackMessage({
          severity: 'error',
          message: 'Could not save changes!'
        });
      });
    }
  }

  return (<ScheduleContext.Provider value={{
    getScheduleInfo: getScheduleInfo,
    getScans: getScans,
    updateScheduleInfo: updateScheduleInfo,
    updateScans: updateScans,
    filename: filename,
    projectName: projectName,

    getSimulationInstructions: getSimulationInstructions,
    updateSimulationInstructions: updateSimulationInstructions, 

    getSimulationDate: getSimulationDate,
    updateSimulationDate: updateSimulationDate,
  
    saveSchedule: saveSchedule,
    saveAsSchedule: saveAsSchedule,
    isDirty: isDirty, 
    setIsDirty: setIsDirty,
    schedule: schedule,
    setSchedule: setSchedule,
    deploySchedule: deploySchedule
  }}>
    {props.children}

    <Snackbar
      open={Boolean(snackMessage)}
      autoHideDuration={6000}
      onClose={e => setSnackMessage(null)}
    >
      <Alert severity={snackMessage ? snackMessage.severity : 'error' }>
        {snackMessage ? snackMessage.message : ''}
      </Alert>
    </Snackbar>

  </ScheduleContext.Provider>);
}
