import * as React from 'react';
import Stack from '@mui/material/Stack';
import { Alert, Breadcrumbs, Divider, IconButton, ListItemIcon, ListItemText, 
  Tooltip, MenuItem, MenuList, Snackbar, Typography, 
  ToggleButtonGroup,
  ToggleButton} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import InputIcon from '@mui/icons-material/Input';
import OutboundIcon from '@mui/icons-material/Outbound';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import { useNavigate } from 'react-router';
import SystemUpdateAltOutlinedIcon from '@mui/icons-material/SystemUpdateAltOutlined'; 

import ScheduleScanView from './ScheduleScanView';

import { ProjectContext } from './ProjectContext';
import { ScheduleContext } from './ScheduleContext';
import SaveScheduleDialog from '../components/SaveScheduleDialog';
import DeployScheduleDialog from '../components/DeployScheduleDialog';
import * as Utils from '../util/Utils'
import LeaveDetector from './LeaveDetector';

import './Dropzone.css';
import { useDropzone } from 'react-dropzone';
import ScheduleSimulationView from './ScheduleSimulationView';

import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import ListIcon from '@mui/icons-material/List';

export default function Schedule() {
  const projectContext = React.useContext(ProjectContext);
  const scheduleContext = React.useContext(ScheduleContext);

  const navigate = useNavigate();

  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openSaveFileDialog, setOpenSaveFileDialog] = React.useState(false);
  const [openDeployFileDialog, setOpenDeployFileDialog] = React.useState(false);
  const [snackMessage, setSnackMessage] = React.useState<any>(null);

  const { getRootProps, getInputProps } = useDropzone
  ({maxFiles: 1, 
    multiple: false,
    accept: { 'text/plain': ['.sch']},
    onDrop: acceptedFiles => {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e && e.target) {
          const text = e.target.result as string;
          const schedule: any = JSON.parse(text) || {};
          scheduleContext.setSchedule(schedule);
          scheduleContext.setIsDirty(true);
        }
      }
      reader.readAsText(acceptedFiles[0]);
    }
  });

  const [view, setView] = React.useState<string>('source-view');

  const handleView = (
    _event: React.MouseEvent<HTMLElement>,
    newView: string,
  ) => {
    setView(newView);
  };

  // save changes, no auto save if no filename
  const doSaveSchedule = (fullfilename: string) => {
    if (fullfilename) {
      console.log(`Schedule - save ${fullfilename}`);
      scheduleContext.saveAsSchedule(fullfilename, 
        () => {gotoScheduleFile(fullfilename)}
      );
    }

    setOpenSaveFileDialog(false);
  }

  React.useEffect(() => {
    let interval: any = null;
    if (projectContext.autoSave) {
      interval = setInterval(() => {
        // save changes, no auto save if no filename
        if (scheduleContext.isDirty) {
          if (scheduleContext.filename) {
            console.log('Schedule - auto saving');
            scheduleContext.saveSchedule();
            scheduleContext.setIsDirty(false);
          }
        }
      }, 10000);
    } else {
      clearInterval(interval);
      console.log('Schedule - clear auto saving');
    }
    return () => clearInterval(interval);
  }, [projectContext.autoSave, scheduleContext.isDirty, scheduleContext]);

  const handleDeploy = () => {
    // display the deploy content and file name in Dialog
    // (give user a chance to download it)
    setMenuAnchorEl(null);
    setOpenDeployFileDialog(true);
  }

  const doDeploy = () => {
    const content = constructFullSchedule();
    const filename = Utils.getFullScheduleName(scheduleContext.projectName, 
      scheduleContext.filename);

    scheduleContext.deploySchedule(content, filename);
    setOpenDeployFileDialog(false);
  }

  const handleSaveAs = () => {
    setOpenSaveFileDialog(true);
    setMenuAnchorEl(null);
  }

  const handleSave = () => {
    if (scheduleContext.filename) {
      if (scheduleContext.isDirty) {
        scheduleContext.saveSchedule();
      }
    } else {
      setOpenSaveFileDialog(true);
    }
    setMenuAnchorEl(null);
  }

  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setMenuAnchorEl(null);
  };

  const gotoScheduleFile = (filename: string) => {
    const route = '/schedule-editor';
    const project = scheduleContext.projectName;
    let fullroute = `${route}?project=${encodeURIComponent(project!)}`;
    if (filename) {
      fullroute += `&file=${filename}`;
    }
    navigate(fullroute);
  }

  /** pull in 
   * - frequency_configuration
   * - correlator_configuration
   * used in the schedule file 
   * and combine everything into a single file
  **/ 
  const constructFullSchedule = () => {
    const schedule = JSON.parse(JSON.stringify(scheduleContext.schedule));
    const scheduleCorrelatorConfig = new Map<string, any>();

    const correlatorConfigrations = projectContext.correlatorConfigurations;
    const targetList = Utils.getTargets(projectContext.targetFile);

    for (const scan of schedule['scans']) {
      // add ra, dec, coord_sys and velocity
      const targets = targetList.filter((t: any) => (t['name'] === scan['name']));
      if (targets && targets.length > 0) {
        const target = targets[0];
        scan['ra'] = target['ra'];
        scan['dec'] = target['dec'];
        scan['coord_sys'] = target['coord_sys']||'J2000';
        scan['velocity'] = Utils.getTargetVelocityString(target);
      } else {
        scan['message'] = 'Not found in project target catalogue';
      }

      const corrConfigName = scan['correlator_configuration'];
      if (scheduleCorrelatorConfig.get(corrConfigName)) {
        // don't need to do anything if it exists already
        continue;
      }

      const corrConfigs = correlatorConfigrations.filter(c => {
        return c['name'] === corrConfigName;
      });

      // add corr config
      if (corrConfigs.length > 0) {
        scheduleCorrelatorConfig.set(corrConfigName, corrConfigs[0]);
      }
    }

    const fullSchedule = {
      'schedule': schedule,
      'correlator_configurations': Array.from(scheduleCorrelatorConfig.values()),
    }

    return fullSchedule;
  }

  return (
    <LeaveDetector isDirty={scheduleContext.isDirty} >
    <Stack spacing={2} sx={{padding: '10px'}}>
      <Snackbar
        open={Boolean(snackMessage)}
        autoHideDuration={6000}
        onClose={e => setSnackMessage(null)}
      >
        <Alert severity={snackMessage ? snackMessage.severity : 'error'}>
          {snackMessage ? snackMessage.message : ''}
        </Alert>
      </Snackbar>

      <SaveScheduleDialog 
        open={openSaveFileDialog} 
        rootPath={scheduleContext.projectName} 
        handleSave={doSaveSchedule} 
        handleCancel={() => setOpenSaveFileDialog(false)}
      />

      <DeployScheduleDialog
        open={openDeployFileDialog}
        fullFilename={Utils.getFullScheduleName(scheduleContext.projectName, 
          scheduleContext.filename)} 
        schedule_content={JSON.stringify(constructFullSchedule(), null, 4)}
        handleDeploy={doDeploy}
        handleCancel={() => setOpenDeployFileDialog(false)}
      />

      <Stack direction="row" spacing={0} marginBottom={'10px'}
        justifyContent="space-between" alignItems={'center'}
      >
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Typography key="project" variant="subtitle1" color="primary">
            {scheduleContext.projectName}
          </Typography>

          <Typography key="project" variant="subtitle1" color="primary">
            {'Schedule File'}
          </Typography>

          <Typography key="file" variant="h6" 
            color={ scheduleContext.isDirty ? "red" : "primary"}>
            {scheduleContext.filename ? scheduleContext.filename : 'New'}
          </Typography>
        </Breadcrumbs>

        {!scheduleContext.filename &&
          <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />
              <Stack spacing={0}>
                <Typography variant="button"
                  width={'100%'} textAlign='center'>
                  Import Schedule file
                </Typography>
                <Typography className='hide' variant="caption" textAlign='center'>
                  Drag 'n' drop ONE .sch file here, or click to select a file
                </Typography>
              </Stack>
          </div>
        }

        <ToggleButtonGroup
          value={view}
          exclusive
          onChange={handleView}
          aria-label="view"
        >
          <ToggleButton value="source-view" aria-label="source">
            <Tooltip title="Edit scans">
              <ListIcon />
            </Tooltip>
          </ToggleButton>
          <ToggleButton value="simulator-view" aria-label="simulator">
            <Tooltip title="Simple simulation">
              <SportsEsportsIcon />
            </Tooltip>
          </ToggleButton>
        </ToggleButtonGroup>

        <div>
          <Tooltip title="Save schedule changes to server">
            <IconButton aria-label="save" color='primary' 
              sx={{marginRight: '50px'}} onClick={handleSave}>
              <SaveIcon />
            </IconButton>
          </Tooltip>
        
          <IconButton
            size="large"
            edge="start"
            color="primary"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={handleClickMenu}
          >
            <MenuIcon />
          </IconButton>
        </div>
        <Menu
          PaperProps={{ sx: { width: '200px'} }}
          id="menu-appbar"
          anchorEl={menuAnchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: -30,
            horizontal: 'right',
          }}
          open={Boolean(menuAnchorEl)}
          onClose={handleClose}
        >
          <MenuList>
            <MenuItem>
              <ListItemIcon>
                <InputIcon />
              </ListItemIcon>
              <ListItemText>Import</ListItemText>
            </MenuItem>

            <MenuItem onClick={handleSave}>
              <ListItemIcon>
                <SaveIcon />
              </ListItemIcon>
              <ListItemText>Save</ListItemText>
            </MenuItem>

            <MenuItem onClick={handleSaveAs}>
              <ListItemIcon>
                <SaveAsIcon />
              </ListItemIcon>
              <ListItemText>Save as</ListItemText>
            </MenuItem>

            <Divider />

            <MenuItem onClick={handleSaveAs}>
              <ListItemIcon>
                <SystemUpdateAltOutlinedIcon />
              </ListItemIcon>
              <ListItemText>Download</ListItemText>
            </MenuItem>

            <Divider />

            <MenuItem onClick={handleDeploy}>
              <ListItemIcon>
                <OutboundIcon />
              </ListItemIcon>
              <ListItemText>Deploy</ListItemText>
            </MenuItem>

          </MenuList>
        </Menu>
      </Stack>
      { view === 'source-view' ?
        <ScheduleScanView />
        :
        <ScheduleSimulationView />
      }
    </Stack>

    </LeaveDetector>
  )
}
