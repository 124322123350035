import * as React from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { Alert, ButtonGroup, FormControlLabel, FormLabel, IconButton, 
  Paper, Radio, RadioGroup, Snackbar, Switch, Checkbox,
  Tooltip} from '@mui/material';
import PostAddIcon from '@mui/icons-material/PostAdd';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import ContentCutOutlinedIcon from '@mui/icons-material/ContentCutOutlined'; 
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ContentPasteOutlinedIcon from '@mui/icons-material/ContentPasteOutlined';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'; 
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import List from '@mui/material/List';
import * as Utils from '../util/Utils'

import { Box } from '@mui/system';
import {ProjectContext} from './ProjectContext';
import { ScheduleContext } from './ScheduleContext';

import { DndContext, closestCorners } from '@dnd-kit/core';

import ScanEditor from './ScanEditor';
import ImportSourceDialog from '../components/ImportSourceDialog';
import NewTargetDialog from '../components/NewTargetDialog';
import DragableScan from '../components/DragableScan';
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';

export default function ScheduleScanView() {
  const projectContext = React.useContext(ProjectContext);
  const scheduleContext = React.useContext(ScheduleContext);

  const [openSnack, setOpenSnack] = React.useState(false);

  const [showImportDialog, setShowImportDialog] = React.useState<boolean>(false);
  const [openTargetDialog, setOpenTargetDialog] = React.useState(false);

  const [selectedIndex, setSelectedIndex] = React.useState<number[]>([]);

  const [clipBoard, setClipBoard] = React.useState<any[]>([]);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenTargetDialog(true);
  };

  const handleClose = () => {
    setOpenTargetDialog(false);
  };

  const getScans = () => {
    const targetList = Utils.getTargets(projectContext.targetFile);
    const scans: any[] = [];
    for (const scan of scheduleContext.getScans()) {
      if (scan['isCalibrator']) {
        scans.push(scan);
        continue;
      }
      const target = getTarget(targetList, scan['name']);
      if (target) {
        const fullScan = {
          ...target,
          ...scan
        }
        scans.push(fullScan);
      } else {
        scans.push({
          'name': scan['name'],
          'message': 'Not found in project target catalogue'
        });
      }
    }

    return scans;
  }

  const getTarget = (targetList: any[], name: string) => {
    const targets = targetList.filter((t: any) => (t['name'] === name));
    if (targets && targets.length > 0) {
      return targets[0];
    }

    return null;
  }

  const updateSelectedScan = (s : any) => {
    const scans: any[] = scheduleContext.getScans();
    const newScans = [...scans];
    newScans[selectedIndex[0]] = s;
    scheduleContext.updateScans(newScans);
  }

  const importTargets = (targets: any[], defaultImportSetting: any) => {
    const scans: any[] = scheduleContext.getScans();
    let newScans: any[] = [];
    let index = 0;
    for (const target of targets) {
      const scan =  {
        name: target['name'],
        id: Date.now() + index,
        duration: defaultImportSetting['duration'],
        scanType: defaultImportSetting['scanType'],
        pointing: defaultImportSetting['pointing'],
        correlator_configuration: defaultImportSetting['correlator_configuration']
      };
      newScans.push(scan);
      index++;
    }
    newScans = scans.concat(newScans);
    scheduleContext.updateScans(newScans);
    setSelectedIndex([0]);
    setShowImportDialog(false);
  }

  const cutScans = () => {
    const scans: any[] = scheduleContext.getScans();
    const seletedScans = scans.filter((value, index) => {
      return selectedIndex.indexOf(index) >= 0;
    });
    setClipBoard(seletedScans);

    const newScans = scans.filter((value, index) => {
      return selectedIndex.indexOf(index) === -1;
    });

    scheduleContext.updateScans(newScans);
    setSelectedIndex([]);
  }

  const copyScans = () => {
    const scans: any[] = scheduleContext.getScans();
    // copy selected scans
    const selectedScans = [];
    for (const index of selectedIndex) {
      const scan = scans[index];
      const copyScan = JSON.parse(JSON.stringify(scan));
      copyScan['id'] = Date.now() + index;
      selectedScans.push(copyScan);
    }
    setClipBoard(selectedScans);
  }

  const pasteScans = () => {
    const scans: any[] = scheduleContext.getScans();
    // if no item selected, paste at the beginning    
    // if item selected, paste after the selected item
    let index = 0;
    if (selectedIndex.length > 0) {
      index = selectedIndex[0] + 1;
    }

    if (index >= scans.length) {
      const newScans = scans.concat(clipBoard);
      scheduleContext.updateScans([...newScans]);
    } else {
      scans.splice(index, 0, ...clipBoard);
      scheduleContext.updateScans([...scans]);
    }

    const selection = [...Array(clipBoard.length)].map((_, i) => index + i);
    setSelectedIndex(selection);
  }

  const addNewScan = (scan: any) => {
    // error if the scan name already in catelogue already
    // and it's different
    let content = projectContext.targetFile;
    const targets = Utils.getTargets(content);

    let foundTarget = false;
    let different = false;
    for (let target of targets) {
      if (target['name'] === scan['name'].trim()) {
        foundTarget = true;
        if (target['ra'] !== scan['ra']) {
          different = true
          break;
        }
        if (target['dec'] !== scan['dec']) {
          different = true
          break;
        }
        if ((target['coord_sys']||'J2000') !== (scan['coord_sys']||'J2000')) {
          different = true
          break;
        }
      }
    }

    if (foundTarget && different) {
      setOpenSnack(true);
      return;
    }

    setOpenTargetDialog(false);

    const scans: any[] = scheduleContext.getScans();
    const newScans = [...scans];

    newScans.unshift(
      {
        name: scan['name'].trim(),
        id: Date.now(),
        calCode: '',
        duration: 300,
        startTime: '',
        startDate: '',
        scanType: 'Normal',
        pointing: 'Global',
        correlatorSetting: ''
      },
    );
    scheduleContext.updateScans(newScans);
    setOpenTargetDialog(false);
    setSelectedIndex([0]);

    // add source to catalogue if it's not in there
    if (foundTarget)
      return;

    content += '\n' + Utils.targetToString(scan);

    projectContext.setTargetFile(content);
    projectContext.saveTargetFile(content);
  }

  const toggleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const scans: any[] = scheduleContext.getScans();
    if (event.target.checked) {
      const selectAll = [...Array(scans.length)].map((_, i) => i);
      setSelectedIndex(selectAll);
    } else {
      setSelectedIndex([]);
    }
  };

  const selectIndex = (index: number) => {
    setSelectedIndex([index]);
  }

  const toggleSelection = (e: React.MouseEvent<Element, MouseEvent>, index: number) => {
    let newSelections = [...selectedIndex];
    const i = selectedIndex.indexOf(index);
    if (i<0) {
      newSelections.push(index);
    } else {
      newSelections.splice(i, 1);
    }

    setSelectedIndex(newSelections.sort((n1, n2) => n1 - n2));
    e.stopPropagation();
  }

  const handleScheduleInfoChange = 
    (value: any, fieldName: string) => {

    const scheduleInfo = scheduleContext.getScheduleInfo();
    const newScheduleInfo = {
      ...scheduleInfo,
      [fieldName]: value
    };
      scheduleContext.updateScheduleInfo(newScheduleInfo);
  };

  const DragableList = (scans: any[]) => (
    <SortableContext items={scans} strategy={verticalListSortingStrategy}>
      <List sx={{ padding: 0 }}>
        {scans.map((scan, index) => (
            <DragableScan scan={scan} 
              key={scan.id}
              index={index}
              selectedIndex={selectedIndex}
              toggleSelection={toggleSelection}
              selectIndex={selectIndex}
            />
        ))}
      </List>
    </SortableContext>
  );

  const ObservationDetails = (
    <Stack spacing={2} padding={'10px'}>

      <TextField id="description" label="Description"
        value={scheduleContext.getScheduleInfo()['description'] || ''}
        onChange={(e) => handleScheduleInfoChange(e.target.value as string, 'description')}
        variant="standard" sx={{ flexGrow: 1 }} />

      <Stack direction="row" spacing={2} alignItems='flex-end'>
        <TextField id="observer" label="Observer"
          value={scheduleContext.getScheduleInfo()['observer'] || ''}
          onChange={(e) => handleScheduleInfoChange(e.target.value as string, 'observer')}
          variant="standard" sx={{ flexGrow: 1 }}/>

        <TextField id="proposal-code" label="Proposal Code" 
          value={scheduleContext.getScheduleInfo()['proposal_code'] || ''}
          onChange={(e) => handleScheduleInfoChange(e.target.value as string, 'proposal_code')}
          variant="standard" sx={{ flexGrow: 1 }} />
          
        <TextField id="schedule-owner" label="Schedule Owner" 
          value={scheduleContext.getScheduleInfo()['owner']}
          onChange={(e) => handleScheduleInfoChange(e.target.value as string, 'owner')}
          variant="standard" sx={{ flexGrow: 1 }} />
      </Stack>

    </Stack>
  );

  const ScheduleType = (
    <Stack spacing={2} padding={'10px'}>
      <FormControl fullWidth sx={{ flexDirection: 'row', alignItems: 'center' }}>
        <FormLabel id="schedule-type-label" sx={{ minWidth: '100px' }}>
          Start time
        </FormLabel>
        <RadioGroup
          row
          name="schedule-type-group"
          sx={{ width: 'calc(100% - 100px)' }}
          value={scheduleContext.getScheduleInfo()['schedule_type'] || ''}
          onChange={(e) => handleScheduleInfoChange(e.target.value as string, 'schedule_type')}
        >
          <FormControlLabel value="relative" control={<Radio />} label="Relative" />
          <FormControlLabel value="lst" control={<Radio />} label="LST" />
          <FormControlLabel value="utc" control={<Radio />} label="UTC" />
        </RadioGroup>
      </FormControl>     

      <FormControlLabel
        control={
          <Switch 
            checked={Boolean(scheduleContext.getScheduleInfo()['advance_mode'])} 
            onChange={(e) => handleScheduleInfoChange(e.target.checked, 'advance_mode')}
            name="advance-mode" />
        }
        label="Advanced Mode"
      />
    </Stack>
  );

  const ImportDialog = (
    <ImportSourceDialog
      open={showImportDialog}
      targets={projectContext.targetFile}
      handleImport={importTargets}
      handleCancel={() => setShowImportDialog(false)}
    />
  );

  function handleDragEnd(event: any): void {
    const {active, over} = event;

    if (active.id === over.id) {
      return;
    }

    if (active.data.current.sortable && over.data.current.sortable) {
      const newScans = arrayMove(getScans(),
        active.data.current.sortable.index, over.data.current.sortable.index);

      scheduleContext.updateScans(newScans);
      setSelectedIndex([]);
    }
  }

  return (
    <React.Fragment>
      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={e => setOpenSnack(false)}
      >
        <Alert severity="error">
          A target with same name but different values exists in catalogue.
        </Alert>
      </Snackbar>

      <Stack direction={'row'} spacing={2} style={{ marginTop: 0 }}>
        <Paper sx={{ width: '60%', flexGrow: 1 }}>
          <Typography variant="h6"
            sx={{ backgroundColor: '#E0E0E0', 'textAlign': 'center', padding: '5px' }}>
            Schedule Information
          </Typography>
          {ObservationDetails}
        </Paper>

        <Paper sx={{ minWidth: '250px', maxWidth: '450px' }}>
          <Typography variant="h6"
            sx={{ backgroundColor: '#E0E0E0', 'textAlign': 'center', padding: '5px' }}>
            Schedule Type
          </Typography>
          {ScheduleType}
        </Paper>
      </Stack>

      <Stack direction="row" spacing={3} style={{ marginTop: 0 }}
        justifyContent="space-between">
        <ButtonGroup sx={{marginLeft: '5px'}}>
          <Tooltip title="Select/Unselect all">
            <Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 40 } }}
              icon={<RadioButtonUncheckedIcon />}
              checkedIcon={<CheckCircleIcon />}
              onChange={toggleSelectAll}
              checked={selectedIndex.length === scheduleContext.getScans().length}
            />
          </Tooltip>

          <Tooltip title="Cut/Delete">
            <IconButton aria-label="cut-scan" onClick={cutScans}
              disabled={selectedIndex.length < 1}>
              <ContentCutOutlinedIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Copy">
            <IconButton aria-label="copy-scan" onClick={copyScans}
              disabled={selectedIndex.length < 1}>
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Paste">
            <IconButton aria-label="paste-scan" onClick={pasteScans}
              disabled={clipBoard.length < 1 || selectedIndex.length > 1}>
              <ContentPasteOutlinedIcon />
            </IconButton>
          </Tooltip>
        </ButtonGroup>

        <Stack direction="row" spacing={5} justifyContent="flex-end">
          <Button variant="text" startIcon={<PostAddIcon />} 
            onClick={e => setShowImportDialog(true)}>
            Add Targets from Catalogue
          </Button>
          {ImportDialog}
          <Button variant="text" 
            onClick={handleMenu}
            startIcon={<ControlPointIcon />}>
            Add Scan (New Target)
          </Button>
          <NewTargetDialog addTarget={addNewScan} 
            open={openTargetDialog}
            handleClose={handleClose}
            message='Target will be added to project catalogue.'
          />
        </Stack>
      </Stack>

      <Stack direction={'row'} style={{ marginTop: 0 }} 
        sx={{height: 'calc(100vh - 385px)'}}>
        <Box sx={{
          width: '35%', maxWidth: '450px', minWidth: '350px',
          border: '1px solid #eeeeee', overflowY: 'scroll'
        }}>
          <DndContext onDragEnd={handleDragEnd}
            collisionDetection={closestCorners}>
            {DragableList(getScans())}
          </DndContext>
        </Box>
        {
          (selectedIndex.length === 1 && scheduleContext.getScans().length > 0 
            && scheduleContext.getScans().length > selectedIndex[0] ) &&
            <ScanEditor
              scan={scheduleContext.getScans()[selectedIndex[0]] }
              setScan={updateSelectedScan }
            />
        }
      </Stack>
    </React.Fragment>
  )
}