import * as React from 'react';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en-gb';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { post } from 'aws-amplify/api';

import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { Alert, Backdrop, Button, Checkbox, CircularProgress, IconButton, ListItem, ListItemIcon, ListItemText, Menu, Paper, Snackbar, Tooltip} from '@mui/material';
import TextField from '@mui/material/TextField';

import List from '@mui/material/List';
import * as ATCAConstants from '../util/ATCAConstants'
import * as Utils from '../util/Utils'

import { Box } from '@mui/system';
import {ProjectContext} from './ProjectContext';
import { ScheduleContext } from './ScheduleContext';

dayjs.extend(utc);


/*
start
start 1/1
start 5
start 5/2
start 2-4/2
*/

export default function ScheduleSimulationView() {
  const scheduleContext = React.useContext(ScheduleContext);
  const [selectedIndex, setSelectedIndex] = React.useState<number[]>([]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  
  const [simulationText, setSimulationText] = React.useState<any>({});

  const [plot, setPlot] = React.useState<any>(null);

  React.useEffect(() => {
    const length = (scheduleContext.getScans()||[]).length;
    setSelectedIndex(Array.from(Array(length).keys()));
  }, [scheduleContext.schedule]);

  function doSimulateText(scans: number[], utcDate: string): void {
    const getOperation = post({
      apiName: 'cabb',
      path: '/simulation_text',
      options: {
        headers: {'Accept': 'image/png'},
        body: {
          project: scheduleContext.projectName,
          schedule: scheduleContext.filename,
          startDateTime: utcDate,
          scans: scans
        }
      }
    });

    getOperation.response
    .then(response => response.body.json())
    .then((response: any) => {
      if (response['status'] === 'success') {
        setSimulationText(response['content']);
      } else {
        const message = response['message'];
          setOpenSnack(true);
          setErrorMessage(message);
      }
    })
    .catch((err: any) => {
      console.error('Error fetching simulation text:', err);
      setErrorMessage('Error fetching simulation text: ' + err.toString())
    })
    .finally(() => {
      setOpenBackdrop(false);
    });
  }

  function doSimulateElPlot(scans: number[], utcDate: string): void {    
    const getOperation = post({
      apiName: 'cabb',
      path: '/simulation_el_plot',
      options: {
        headers: {'Accept': 'image/png'},
        body: {
          project: scheduleContext.projectName,
          schedule: scheduleContext.filename,
          startDateTime: utcDate,
          scans: scans
        }
      }
    });

    getOperation.response
    .then(response => response.body.blob()
    )
    .then((blob: any) => {
      setPlot({image: 
        URL.createObjectURL(blob)
      })
    })
    .catch((err: any) => {
      console.error('Error fetching simulation el plot:', err);
      setErrorMessage('Error fetching simulation el plot: ' + err.toString())
    })
    .finally(() => {
      setOpenBackdrop(false);
    });
  }

  function doPlotSources(): void {
      setAnchorEl(null);
      setOpenBackdrop(true);

      const scans = selectedIndex.map(i => i + 1);
      const utcDate = dayjs.utc(scheduleContext.getSimulationDate()).format();

      const getOperation = post({
        apiName: 'cabb',
        path: '/plot_scans',
        options: {
          headers: {'Accept': 'image/png'},
          body: {
            project: scheduleContext.projectName,
            schedule: scheduleContext.filename,
            startDateTime: utcDate,
            scans: scans
          }
        }
      });

      getOperation.response
      .then(response => response.body.blob()
      )
      .then((blob: any) => {
        setPlot({image: 
          URL.createObjectURL(blob)
        })
      })
      .catch((err: any) => {
        console.error('Error fetching plot data:', err);
        setErrorMessage('Error fetching plot data: ' + err.toString())
      })
      .finally(() => {
        setOpenBackdrop(false);
      });
  }

  function runSimulation(): void {
    setOpenBackdrop(true);

    let totalScans: number[] = [];
    const length = (scheduleContext.getScans()||[]).length;

    console.log('length:', length);

    scheduleContext.getSimulationInstructions().forEach(line => {
      if (line.trim()) {
        const scans = Utils.instructionToScans(line, length);
        totalScans = totalScans.concat(scans);
      }
    });

    console.log('Total scans:', totalScans);

    const utcDate = dayjs.utc(scheduleContext.getSimulationDate()).format();

    doSimulateElPlot(totalScans, utcDate);
    doSimulateText(totalScans, utcDate);
  }

  const toggleSelection = (index: number) => {
    let newSelections = [...selectedIndex];
    const i = selectedIndex.indexOf(index);
    if (i < 0) {
      newSelections.push(index);
    } else {
      newSelections.splice(i, 1);
    }

    setSelectedIndex(newSelections.sort((n1, n2) => n1 - n2));
  }

  function updateInstructions(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
    const str = event.target.value;
    const instructions = (str||'').split('\n');
    scheduleContext.updateSimulationInstructions(instructions);
  }

  function getColor(text: string): string {
    if (text.includes('[31m')) {
      return '#f44336';
    }

    if (text.includes('[35m')) {
      return '#9c27b0';
    }

    return '';
  }

  return (
    <React.Fragment>
      <Backdrop
        sx={
          (theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })
        }
        open={openBackdrop}
        onClick={e => setOpenBackdrop(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      
      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={e => setOpenSnack(false)}
      >
        <Alert severity="error"> { errorMessage } </Alert>
      </Snackbar>

      <Stack direction={'row'} spacing={2} style={{ marginTop: 0 }}>
        <TextField
          id="outlined-multiline-flexible"
          label="Instructions"
          fullWidth
          multiline
          rows={5}
          sx={{ width: '60%', flexGrow: 1 }}
          value={scheduleContext.getSimulationInstructions().join('\n')}
          onChange={updateInstructions}
        />

        <Stack sx={{ minWidth: '250px', flexGrow: 1 }} 
          alignItems={'flex-end'} justifyContent={'space-between'}>

          <React.Fragment>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en-gb'}>
              <DateTimePicker label="UTC start date and time" 
                sx={{ width: '100%' }} 
                format='YYYY-MM-DD HH:mm:ss'
                value={dayjs.utc(scheduleContext.getSimulationDate())}
                onChange={(e) => scheduleContext.updateSimulationDate((e ? e : dayjs.utc()).format())}
              />
            </LocalizationProvider>
          </React.Fragment>

          <Stack spacing={2} direction={'row'} width={'100%'}>
            <Button color="primary" sx={{flexGrow: 2}}
              aria-label="setting" variant="outlined"
              onClick={runSimulation}
            >
            Run Simulation
            </Button>

            <Button color="primary" sx={{flexGrow: 2}}
              aria-label="setting" variant="outlined"
              onClick={(event) => {setAnchorEl(event.currentTarget)}}
            >
            Plot Sources
            </Button>

            <Menu
              slotProps={{ paper: { sx: { width: '250px', 
                          height: '500px', padding: '20px'  } } }}
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: -45,
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={e => setAnchorEl(null)}
            >
              <Stack spacing={2}>

                <List sx={{
                  padding: 0, overflowY: 'scroll', height: '425px'
                }}>
                  {scheduleContext.getScans().map((scan: any, index) => (
                    <ListItem key={`target-${index}`}
                      role="listitem"
                      onClick={e => toggleSelection(index)}
                      sx={{
                        padding: '10px',
                        bgcolor: (selectedIndex.indexOf(index) >= 0) ? '#e0f7fa' : '',
                        "&:hover": {
                          cursor: 'pointer',
                          bgcolor: '#f5f5f5',
                        }
                      }}>
                      <ListItemIcon>
                        <Checkbox
                          checked={selectedIndex.indexOf(index) >= 0}
                          disableRipple
                        />
                      </ListItemIcon>
                      <ListItemText primary={scan['name']}/>
                    </ListItem>
                  ))}
                </List>

                <Button fullWidth variant="contained"
                  onClick={doPlotSources}>Plot</Button>
              </Stack>

            </Menu>
          </Stack>
        </Stack>
      </Stack>

      <Stack direction={'row'} spacing={3}
        sx={{height: 'calc(100vh - 320px)'}}>
        <Box sx={{
          width: '60%', minWidth: '350px',
          border: '1px solid #eeeeee', overflowY: 'scroll'
        }}>
        {(simulationText['simulation']||[]).map((text: string, index: number) => (
          <Typography key={'simulation-text-' + index} 
            color = {getColor(text)}
            variant="body2">
            {text.replace('[31m', '').replace('[m', '').replace('[35m', '')}
          </Typography>
        ))}
        {(simulationText['summary']||[]).map((text: string, index: number) => (
          <Typography key={'simulation-text-' + index} 
            color={'#4caf50'} variant="body1">
            {text.replace('[32m', '').replace('[m', '')}
          </Typography>
        ))}
        </Box>

        {plot ? <img src={plot.image}/>: ''}
      </Stack>
    </React.Fragment>
  );
}